<template>
  <div class="ver-panel">
    <div class="ver-head">
      <div class="l-title">注册 / 登陆</div>
      <div class="l-desc"></div>
    </div>
    <el-form :model="ruleForm" ref="ruleForm" :rules="rules">
      <el-form-item prop="phone">
        <div class="input-select-group" :class="'select' + ruleForm.select">
          <el-select
            v-model="ruleForm.select"
            slot="prepend"
            placeholder="请选择"
          >
            <el-option
              v-for="item in countrys"
              :label="item.label"
              :key="item.val"
              :value="item.val"
            ></el-option>
          </el-select>
          <div class="accountSeperator"></div>
          <div class="flex-col">
            <el-input v-model="ruleForm.phone" placeholder="请输入手机号">
            </el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="code">
        <el-input v-model="ruleForm.code" placeholder="请输入4位短信验证码">
          <el-button
            type="primary"
            slot="suffix"
            class="btn btn-getcode"
            :disabled="sended"
            @click="sendVerificationCode"
            >{{
              sended ? `${countDown}秒后再次发送` : "获取短信验证码"
            }}</el-button
          >
        </el-input>
      </el-form-item>
      <div class="agr-cell">
        <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
        <div class="agr-label">
          <span>《用户协议》</span><span>《隐私政策》</span
          ><span>《版权保护》</span>
        </div>
      </div>
      <el-form-item>
        <div class="ver-foot">
          <el-button class="btn-submit" type="primary" @click="submitForm"
            >注册 / 登陆</el-button
          >
        </div>
      </el-form-item>
    </el-form>
    <div class="login-foot">
      <div class="other-hd"><span>其他登录方式</span></div>
      <div class="login-other">
        <div class="col">
          <router-link to="/loginPassword" class="login-item">
            <img src="../../assets/img/login/lock-icon.png" alt="" />
            <div class="l-label">密码登录</div>
          </router-link>
        </div>
        <div class="col">
          <router-link to="/loginWeixin" class="login-item">
            <img src="../../assets/img/login/weixin-icon.png" alt="" />
            <div class="l-label">微信登录</div>
          </router-link>
        </div>
      </div>
    </div>
    <!--    <div class="login-end">-->
    <!--      没有账户? <router-link to="/">立即注册</router-link>-->
    <!--    </div>-->
  </div>
</template>

<script>
import {
  //   getWechatLoginUrl,
  verifyCodeLogin,
  //   applyCodeLogin,
  sendVerificationCode,
} from "@/service/login";
import { Toast } from "vant";

export default {
  components: {},
  data() {
    return {
      rules: {
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        code: [
          { required: true, message: "请输入4位短信验证码", trigger: "blur" },
        ],
      },
      ruleForm: {
        select: "86",
        phone: "",
        code: "",
      },
      checked: false,
      sended: false,
      countDown: 60,
      countrys: [
        {
          label: "+86 中国",
          val: "86",
        },
        {
          label: "+1 美国 & 加拿大",
          val: "1",
        },
        {
          label: "+61 澳大利亚",
          val: "61",
        },
        {
          label: "+44 英国",
          val: "44",
        },
        {
          label: "+49 德国",
          val: "49",
        },
        {
          label: "+353 爱尔兰",
          val: "353",
        },
        {
          label: "+41 瑞士",
          val: "41",
        },
        {
          label: "+31 荷兰",
          val: "31",
        },
        {
          label: "+33 法国",
          val: "33",
        },
        {
          label: "+34 西班牙",
          val: "34",
        },
        {
          label: "+64 新西兰",
          val: "64",
        },
        {
          label: "+7 俄罗斯",
          val: "7",
        },
        {
          label: "+82 韩国",
          val: "82",
        },
        {
          label: "+81 日本",
          val: "81",
        },
        {
          label: "+971 阿拉伯联合酋长国",
          val: "971",
        },
        {
          label: "+886 台湾",
          val: "886",
        },
        {
          label: "+852 香港",
          val: "852",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    submitForm() {
      if (!this.checked) {
        return Toast("请先阅读并勾选协议");
      } else {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            verifyCodeLogin({
              phoneOrEmail: `${this.ruleForm.select}${this.ruleForm.phone}`,
              code: this.ruleForm.code,
            }).then((res) => {
              if (res.success) {
                Toast("登录成功");
                window.localStorage.setItem(
                  "CSON_PAGE_TOKEN",
                  res.result.token
                );
                setTimeout(() => {
                  window.location.href = "/";
                }, 1500);
              } else {
                Toast(res.message);
              }
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    sendVerificationCode() {
      if (this.ruleForm.phone == "") {
        return this.$message.error("请输入手机号码！");
      }
      const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
      if (!regex.test(`+${this.ruleForm.select}${this.ruleForm.phone}`)) {
        return this.$message.error("请输入正确的手机号！");
      }
      this.slideValidation(() => {
        sendVerificationCode(
          `${this.ruleForm.select}${this.ruleForm.phone}`
        ).then((res) => {
          if (res.success) {
            this.$message.success("发送验证码成功！");
            this.sended = true;
            const countDown = setInterval(() => {
              this.countDown = this.countDown - 1;
              if (this.countDown == 0) {
                clearInterval(countDown);
                this.countDown = 60;
                this.sended = false;
              }
            }, 1000);
          }
        });
      });
    },
    slideValidation(cb) {
      let captchaId = "2060393098";
      const captcha = new TencentCaptcha(captchaId, async (res) => {
        console.log(res);
        if (res.ret === 0) {
          cb();
        }
      });
      captcha.show();
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.ver-head {
  height: 60px;
  text-align: center;
  color: #333;

  .l-title {
    font-size: 20px;
  }

  .l-sub-title {
    font-size: 13px;
  }

  .l-desc {
    color: #999;
    margin-top: 10px;
    font-size: 12px;
  }
}

.btn-getcode {
  display: inline-flex;
  border: 0;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  background-color: #007bf7;
  border-color: #007bf7;
  &.is-disabled {
    color: #c0c4cc;
    background: #fff;
  }
}

.ver-panel {
  padding: 100px 20px 20px 20px;
  min-height: 100vh;
  box-sizing: border-box;
}

.ver-panel ::v-deep .el-input__inner {
  background: #eceef2;
  border-radius: 10px;
  border-color: #eceef2 !important;
}

.ver-panel ::v-deep .el-input__inner::placeholder {
  color: #666;
}

.ver-panel .btn-submit {
  width: 100%;
  margin: 0;
  height: 46px;
  border-radius: 46px;
}

.ver-panel .btn-submit:not(.is-disabled) {
  background: linear-gradient(90deg, #007bf7 0%, #0aabfb 100%);
}

.flex_bd {
  flex: 1;
  min-width: 0;
}

.input-select-group {
  position: relative;
}
.input-select-group {
  display: flex;
  align-items: center;
  background: #eceef2;
  border-radius: 10px;
  border-color: #eceef2 !important;
  position: relative;
}

.flex-col {
  flex: 1;
  min-width: 0;
}
.ver-phone-cell {
  font-size: 14px;
}

.agr-cell ::v-deep .el-checkbox__label {
  color: #666 !important;
  font-size: 12px;
}

.agr-label {
  color: #007bf7;
  font-size: 12px;
}

.agr-cell {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
  margin-bottom: 30px;
}

.other-hd {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 50px;
}

.other-hd span {
  position: relative;
  z-index: 2;
  background-color: #ffffff;
  padding: 0 20px;
  font-size: 14px;
  display: inline-block;
}

.other-hd::after {
  height: 1px;
  background-color: #999999;
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
}

.login-other {
  display: flex;
  padding: 0 30px;
  .col {
    flex: 1;
    text-align: center;
  }

  .login-item img {
    width: 40px;
  }

  .login-item .l-label {
    color: #666;
    font-size: 14px;
    margin-top: 10px;
  }
}

.login-end {
  text-align: center;
  font-size: 14px;
  margin-top: 30px;
  color: #999;

  a {
    color: #007bf7;
  }
}

.input-select-group .el-select {
  width: 110px;
}
.input-select-group.select1 .el-select {
  width: 156px;
}
.input-select-group.select353 .el-select,
.input-select-group.select34 .el-select,
.input-select-group.select64 .el-select {
  width: 130px;
}
.input-select-group.select61 .el-select {
  width: 136px;
}
.input-select-group.select971 .el-select {
  width: 200px;
}
.accountSeperator {
  background: #dbe2ea;
  height: 22px;
  width: 1px;
}
</style>