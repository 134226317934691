import request from "@/utils/request";

export async function getWechatLoginUrl() {
  try {
    return await request({
      url: `/wechat/wechatLogin`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

// 登录
export async function applyLogin(data = {}) {
  try {
    return await request({
      url: "/auth/login",
      method: "post",
      data,
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

// 验证码登录

export async function verifyCodeLogin(data) {
  try {
    return await request({
      url: `/auth/verificationCodeLogin`,
      method: "post",
      data: data,
    });
  } catch (error) {
    return {};
  }
}
// 验证码登录
export async function applyCodeLogin(data) {
  try {
    return await request({
      url: `/auth/verificationCodeLogin`,
      method: "post",
      data: data,
    });
  } catch (error) {
    return {};
  }
}
// 发送验证码
export async function sendVerificationCode(phoneOrEmail) {
  try {
    return await request({
      url: `/code/sendVerificationCode?phoneOrEmail=${phoneOrEmail}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

// 发送验证码
export async function sendVerifyEmail(email) {
  try {
    return await request({
      url: `/code/sendVerificationCode?phoneOrEmail=${email}`,
      method: "get",
    });
  } catch (error) {
    return {};
  }
}

// 绑定邮箱
export async function bindEmail(data) {
  try {
    return await request({
      url: `/auth/bindingEmail`,
      method: "put",
      data,
    });
  } catch (error) {
    return {};
  }
}

// 获取微信参数
export async function getWechatLoginParams(params) {
  try {
    return await request({
      url: `/wechat/getWechatParams`,
      method: "get",
      params,
    });
  } catch (error) {
    return {};
  }
}

// 微信code登录
export async function wechatLogin(params) {
  try {
    return await request({
      url: `/wechat/loginAuthMobile`,
      method: "get",
      params,
    });
  } catch (error) {
    return {};
  }
}
